// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-freebies-js": () => import("./../../../src/pages/freebies.js" /* webpackChunkName: "component---src-pages-freebies-js" */),
  "component---src-pages-shoutout-js": () => import("./../../../src/pages/shoutout.js" /* webpackChunkName: "component---src-pages-shoutout-js" */),
  "component---src-pages-upload-upload-js": () => import("./../../../src/pages/upload/upload.js" /* webpackChunkName: "component---src-pages-upload-upload-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

